import Vue from "vue";
import titleMixin from "@/mixins/title";
export default Vue.extend({
    mixins: [titleMixin],
    data() {
        return {
            title: this.$t("account.confirmEmail.title"),
            isEmailConfirmed: false,
            isPasswordSet: false,
            errorMessage: "",
            newPassword: "",
            newPasswordConfirm: "",
            validationOptions: {
                rules: {
                    newPassword: {
                        required: true,
                        minLength: 8,
                        maxLength: 255
                    },
                    newPasswordConfirm: {
                        required: true,
                        minLength: 8,
                        maxLength: 255,
                        match: [
                            (args) => {
                                return (this.newPassword == this.newPasswordConfirm);
                            },
                            this.$t("account.changePassword.passwordConfirmationNotMatch")
                        ]
                    }
                }
            }
        };
    },
    methods: {
        async confirmEmail() {
            try {
                await this.$store.dispatch('account/confirmEmail', {
                    email: this.$route.query.email,
                    token: this.$route.query.token,
                });
                this.isEmailConfirmed = true;
            }
            catch (e) {
                this.errorMessage = e[0].message;
            }
        },
        async setPassword() {
            await this.$store.dispatch("account/resetPassword", {
                email: this.$route.query.email,
                token: this.$route.query.passwordToken,
                newPassword: this.newPassword,
            });
            this.isPasswordSet = true;
        }
    },
    async mounted() {
        await this.confirmEmail();
    }
});
